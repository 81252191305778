import { AxiosResponse } from "axios";

import http from "../../utils/http";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import {
  setCmsLoader,
  setEducationData,
  setEducationLoader,
  setEducationPage,
  setLearningData,
  setTotalEducationData,
  toggleCMS,
} from "../reducers/cmsSlice";
import { AppThunk } from "../store";
import { downloadFile } from "../../utils/education";
import { langMap } from "../../utils/lang";
import { DateTime } from "luxon";
import { pageSize } from "../../Components/Common/styles/table";

export const fetchLearningData =
  (searchText: string, languageFilter: any): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setCmsLoader(true));
      let url = `/lms/categories`;
      if (searchText) {
        url += `?search=${searchText}`;
      }
      if (searchText && languageFilter.length > 0) {
        url += `&lang=${languageFilter.join(",")}`;
      } else if (languageFilter.length > 0) {
        url += `?lang=${languageFilter.join(",")}`;
      }
      const res: AxiosResponse = await http.get(url);
      const newData = res.data?.data?.map((module: any) => {
        return {
          id: module?.id,
          name: module?.name,
          lessonCount: module?.lessonCount,
          lang: module?.lang || "en",
          langLabel: langMap[module?.lang] || "English",
        };
      });

      dispatch(setLearningData(newData));
      dispatch(setCmsLoader(false));
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setCmsLoader(false));
    }
  };

export const fetchEducationData =
  (
    searchText: string,
    filter: any,
    languageFilter: any,
    page: number,
    order: string
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setEducationLoader({ loadState: true }));
      let url = `/lms/lessons?page=${page + 1}&size=${pageSize}`;
      if (searchText) {
        url += `&search=${searchText}`;
      }
      if (filter.length > 0) {
        url += `&status=${filter.join(",")}`;
      }
      if (languageFilter.length > 0) {
        url += `&lang=${languageFilter.join(",")}`;
      }
      if (order) {
        url += `&order=${order}`;
      }
      const res: AxiosResponse = await http.get(url);

      if (
        res?.data?.data?.educationLessons.length === 0 &&
        res?.data?.data?.count > 0 &&
        page > 0
      ) {
        dispatch(setEducationPage(0));
        return;
      }

      const data = res.data?.data?.educationLessons.map((module: any) => {
        return {
          name: module?.name,
          imageUrl: module?.imageUrl,
          id: module?.id,
          status: module?.status,
          pageCount: module?.pageCount,
          lang: module?.lang || "en",
          langLabel: langMap[module?.lang] || "English",
          updatedAt: DateTime.fromISO(module?.updatedAt).toFormat(
            "dd'-'LL'-'yyyy"
          ),
          externalName: module?.externalName || "-",
          adaptive: module?.adaptive || false,
        };
      });

      dispatch(setEducationData(data));
      dispatch(setTotalEducationData(res?.data?.data?.count));

      dispatch(setEducationLoader({ loadState: false }));
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setEducationLoader({ loadState: false }));
    }
  };

export const deleteLearningModule =
  (moduleId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setCmsLoader(true));
      await http.delete(`/lms/categories/${moduleId}`);
      toastMessage("success", "Learning Module deleted successfully");
      dispatch(toggleCMS());
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setCmsLoader(false));
    }
  };

export const deleteEducationLesson =
  (moduleId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setCmsLoader(true));
      const res: AxiosResponse = await http.delete(`/lms/lessons/${moduleId}`);
      toastMessage("success", res.data.message);
      dispatch(toggleCMS());
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setCmsLoader(false));
    }
  };

export const duplicateEducationLesson =
  (moduleId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setCmsLoader(true));
      const res: AxiosResponse = await http.post(
        `/lms/lessons/${moduleId}/duplicate`
      );
      toastMessage("success", res.data.message);
      dispatch(toggleCMS());
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setCmsLoader(false));
    }
  };

export const changeEducationLessonStatus =
  (moduleId: string, status: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setCmsLoader(true));
      const res: AxiosResponse = await http.put(`/lms/lessons/${moduleId}`, {
        status,
      });
      toastMessage("success", res.data.message);
      dispatch(toggleCMS());
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setCmsLoader(false));
    }
  };

export const exportEducationLesson =
  (moduleId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setCmsLoader(true));
      const res: AxiosResponse = await http.get(`/lms/lessons/${moduleId}`);
      downloadFile(res.data.data);
      dispatch(setCmsLoader(false));
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setCmsLoader(false));
    }
  };

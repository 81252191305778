import {
  Archive,
  ContentCopy,
  Delete,
  Edit,
  IosShare,
  FilterAlt,
  Restore,
  MoreVert,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  Checkbox,
  Table,
  TableHead,
  TableRow,
  // TableSortLabel,
  IconButton,
  TableBody,
  Avatar,
  LinearProgress,
  TableFooter,
  TablePagination,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  changeEducationLessonStatus,
  deleteEducationLesson,
  duplicateEducationLesson,
  exportEducationLesson,
  fetchEducationData,
} from "../../../../Redux/actions/cmsAction";
import { useAppDispatch, useAppSelector } from "../../../../Redux/hooks";
import {
  clearEducationFilter,
  removeEducationFilter,
  setEducationFilter,
  setModalEducationId,
  setEducationPage,
  setCmsSort,
} from "../../../../Redux/reducers/cmsSlice";
import { toastMessage } from "../../../../utils/toast";
import {
  NoDataContainer,
  StyledSortLabel,
  // StyledSortLabel,
  StyledTableCell,
  paginationLabel,
} from "../../../Common/styles/table";
import PhotoSizeSelectActualOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActualOutlined";
import { pageSize } from "../../../Common/styles/table";
import { TablePaginationStyle } from "../../../Common/styles/table";

const EducationModules = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [filterEl, setFilterEl] = useState<null | HTMLElement>(null);
  // const [languageFilterEl, setLanguageFilterEl] = useState<null | HTMLElement>(
  //   null
  // );
  // const openLanguageFilter = Boolean(languageFilterEl);
  const openFilter = Boolean(filterEl);
  const [selectedModule, setSelectedModule] = useState<any>(null);

  const {
    educationData,
    searchText,
    educationFilter,
    languageFilter,
    loading,
    totalEducationData,
    educationPage,
    toggler,
    sortOrder,
    sortColumn,
  } = useAppSelector((state) => state.cms);

  const openMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    module: any
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedModule(module);
  };

  const closeMenu = () => {
    setAnchorEl(null);
    setSelectedModule(null);
  };

  useEffect(() => {
    dispatch(
      fetchEducationData(
        searchText,
        educationFilter,
        languageFilter,
        educationPage,
        sortOrder
      )
    );
  }, [
    dispatch,
    searchText,
    educationFilter,
    languageFilter,
    toggler,
    educationPage,
    sortOrder,
  ]);

  const openFilterMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFilterEl(event.currentTarget);
  };

  const closeFilterMenu = () => {
    setFilterEl(null);
  };

  // const openLanguageFilterMenu = (
  //   event: React.MouseEvent<HTMLButtonElement>
  // ) => {
  //   setLanguageFilterEl(event.currentTarget);
  // };

  // const closeLanguageFilterMenu = () => {
  //   setLanguageFilterEl(null);
  // };

  const handleFilter = (value: string) => {
    if (educationFilter.includes(value)) {
      dispatch(removeEducationFilter(value));
    } else {
      dispatch(setEducationFilter(value));
    }
    setFilterEl(null);
  };

  const clearFilter = () => {
    dispatch(clearEducationFilter());
    setFilterEl(null);
  };

  // const handleLanguageFilter = (value: string) => {
  //   dispatch(setLanguageFilter(value));
  //   setLanguageFilterEl(null);
  // };

  // const clearLangFilter = () => {
  //   dispatch(clearLanguageFilter());
  //   setLanguageFilterEl(null);
  // };

  const handleAction = (action: string, module?: any) => {
    const targetId = selectedModule?.id;
    if (action === "click") {
      navigate("/app/cms/" + module.id);
    } else if (action === "edit" && targetId) {
      closeMenu();
      dispatch(setModalEducationId(targetId));
    } else if (action === "delete" && targetId) {
      closeMenu();
      dispatch(deleteEducationLesson(targetId));
    } else if (action === "duplicate" && targetId) {
      closeMenu();
      dispatch(duplicateEducationLesson(targetId));
    } else if (action === "export" && targetId) {
      closeMenu();
      if (selectedModule?.pageCount > 0) {
        dispatch(exportEducationLesson(targetId));
      } else {
        toastMessage("warning", "Empty Lesson cannot be exported");
      }
    } else if (action === "restore" && targetId) {
      closeMenu();
      dispatch(changeEducationLessonStatus(targetId, "active"));
    } else if (action === "archive" && targetId) {
      closeMenu();
      dispatch(changeEducationLessonStatus(targetId, "archived"));
    }
  };

  const handlePageChange = (_1: any, newPage: number) => {
    dispatch(setEducationPage(newPage));
  };

  const handleSort = (order: string, column: string) => {
    if (sortColumn === column && sortOrder === order) {
      dispatch(setCmsSort({ column: "", order: "" }));
    } else {
      dispatch(setCmsSort({ column, order }));
    }
  };

  const SortLabel = ({ column }: { column: string }) => {
    return (
      <>
        <StyledSortLabel
          active={column === sortColumn && sortOrder === "asc"}
          direction="asc"
          hideSortIcon={false}
          onClick={() => handleSort("asc", column)}
        />
        <StyledSortLabel
          active={column === sortColumn && sortOrder === "desc"}
          direction="desc"
          hideSortIcon={false}
          onClick={() => handleSort("desc", column)}
        />
      </>
    );
  };

  return (
    <>
      <Box>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                Name
                <SortLabel column="name" />
              </StyledTableCell>
              <StyledTableCell>User Title</StyledTableCell>
              <StyledTableCell>Update On</StyledTableCell>
              <StyledTableCell>Unit Count</StyledTableCell>
              {/* <StyledTableCell>
                Language{" "}
                <IconButton onClick={openLanguageFilterMenu}>
                  <FilterAlt
                    color={languageFilter.length > 0 ? "primary" : "disabled"}
                  />
                </IconButton>
              </StyledTableCell> */}
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell align="right">
                <IconButton onClick={openFilterMenu}>
                  <FilterAlt
                    color={educationFilter.length > 0 ? "primary" : "disabled"}
                  />
                </IconButton>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          {!loading && educationData.length > 0 && (
            <>
              <TableBody>
                {educationData?.map((row) => (
                  <TableRow key={row?.id}>
                    <StyledTableCell
                      onClick={() => {
                        handleAction("click", row);
                      }}
                      sx={{
                        display: "flex",
                        gap: 2,
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <Avatar
                        src={row.imageUrl}
                        sx={{
                          width: 50,
                          height: 50,
                          fontSize: 14,
                          bgcolor: "lightgray",
                          color: "#000",
                        }}
                      >
                        {" "}
                        <PhotoSizeSelectActualOutlinedIcon />
                      </Avatar>
                      <Box>
                        {row?.name}
                        <Typography>
                          {row?.adaptive
                            ? "Visible in Library"
                            : "Not Visible in Library"}
                        </Typography>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell sx={{ maxWidth: "250px" }}>
                      <Typography noWrap fontWeight={500} fontSize="16px">
                        {row?.externalName}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>{row?.updatedAt}</StyledTableCell>
                    <StyledTableCell>{row?.pageCount}</StyledTableCell>
                    {/* <StyledTableCell>{row?.langLabel}</StyledTableCell> */}
                    <StyledTableCell sx={{ textTransform: "capitalize" }}>
                      {row?.status}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <IconButton
                        onClick={(e) => {
                          openMenu(e, row);
                        }}
                      >
                        <MoreVert />
                      </IconButton>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
              {totalEducationData > pageSize && (
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      sx={TablePaginationStyle}
                      count={totalEducationData}
                      page={educationPage}
                      rowsPerPage={pageSize}
                      rowsPerPageOptions={[pageSize]}
                      onPageChange={handlePageChange}
                      labelDisplayedRows={paginationLabel}
                    />
                  </TableRow>
                </TableFooter>
              )}
            </>
          )}
        </Table>
        {!loading && educationData.length === 0 && (
          <NoDataContainer>
            <Typography variant="body1" color="gray">
              No Data
            </Typography>
          </NoDataContainer>
        )}
        {loading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        PaperProps={{
          style: {
            minWidth: "225px",
          },
        }}
      >
        <MenuItem
          key="edit"
          onClick={() => {
            handleAction("edit");
          }}
        >
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem
          key="duplicate"
          onClick={() => {
            handleAction("duplicate");
          }}
        >
          <ListItemIcon>
            <ContentCopy fontSize="small" />
          </ListItemIcon>
          <ListItemText>Duplicate</ListItemText>
        </MenuItem>
        <MenuItem
          key="export"
          onClick={() => {
            handleAction("export");
          }}
        >
          <ListItemIcon>
            <IosShare fontSize="small" />
          </ListItemIcon>
          <ListItemText>Export</ListItemText>
        </MenuItem>
        {selectedModule && selectedModule?.status !== "draft" && (
          <MenuItem
            key="restore"
            onClick={() => {
              handleAction(
                selectedModule?.status === "archived" ? "restore" : "archive"
              );
            }}
          >
            <ListItemIcon>
              {selectedModule?.status === "archived" ? (
                <Restore fontSize="small" />
              ) : (
                <Archive fontSize="small" />
              )}
            </ListItemIcon>
            <ListItemText>
              {selectedModule?.status === "archived" ? "Restore" : "Archive"}
            </ListItemText>
          </MenuItem>
        )}
        <Divider />
        <MenuItem
          key="delete"
          onClick={() => {
            handleAction("delete");
          }}
        >
          <ListItemIcon>
            <Delete fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={filterEl}
        open={openFilter}
        onClose={closeFilterMenu}
        sx={{
          "& .MuiPaper-root": {
            width: 200,
          },
        }}
      >
        {["draft", "active", "archived"].map((item) => (
          <MenuItem key={item} onClick={() => handleFilter(item)}>
            <ListItemIcon>
              <Checkbox checked={educationFilter.includes(item)} />
            </ListItemIcon>
            <ListItemText sx={{ textTransform: "capitalize" }}>
              {item === "active" ? "published" : item}
            </ListItemText>
          </MenuItem>
        ))}
        <Divider />
        <MenuItem onClick={clearFilter}>Clear Filter</MenuItem>
      </Menu>
      {/* <Menu
        anchorEl={languageFilterEl}
        open={openLanguageFilter}
        onClose={closeLanguageFilterMenu}
        sx={{
          "& .MuiPaper-root": {
            width: 200,
          },
        }}
      >
        {LANGUAGES.map((language) => (
          <MenuItem
            key={language.value}
            onClick={() => handleLanguageFilter(language.value)}
          >
            <ListItemIcon>
              <Radio checked={languageFilter.includes(language.value)} />
            </ListItemIcon>
            <ListItemText sx={{ textTransform: "capitalize" }}>
              {language.label}
            </ListItemText>
          </MenuItem>
        ))}
        <Divider />
        <MenuItem onClick={clearLangFilter}>Clear Filter</MenuItem>
      </Menu> */}
    </>
  );
};

export default EducationModules;

import { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import { ModalBaseStyles, ModalHeader } from "../../Common/styles/modal";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import http from "../../../utils/http";
import { CalendarPicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";

type Props = {
  showModal: boolean;
  closeModal: () => void;
  refreshPage: () => void;
  date: string;
  minDate: string;
  id: any;
};

const DatepickerModal = ({
  showModal,
  closeModal,
  date,
  minDate,
  refreshPage,
  id,
}: Props) => {
  const [withdrawalDate, setWithdrawalDate] = useState(
    date ? DateTime.fromFormat(date, "dd/LL/yyyy") : null
  );
  const [buttonLoader, setButtonLoader] = useState(false);

  const submitHandler = async () => {
    try {
      setButtonLoader(true);
      if (!withdrawalDate) {
        throw new Error("Please select a date to proceed");
      }
      const body: any = {
        withdrawalDate: withdrawalDate.toUTC().toISO(),
      };
      const res = await http.patch(`/participants/${id}/withdrawal`, body);
      toastMessage("success", res.data?.message);
      closeModal();
      refreshPage();
    } catch (err) {
      setButtonLoader(false);
      errorToastMessage(err as Error);
    }
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={{ ...ModalBaseStyles, minHeight: "10vh", width: "fit-content" }}>
        <ModalHeader title={``} onCloseClick={closeModal} />
        {withdrawalDate ? (
          <Typography variant="body1" fontWeight={"medium"} sx={{ pl: 3 }}>
            {withdrawalDate.toFormat("dd/LL/yyyy")}
          </Typography>
        ) : (
          <Typography
            variant="body1"
            fontWeight={"regular"}
            sx={{ pl: 3, color: "gray" }}
          >
            DD/MM/YYYY
          </Typography>
        )}
        <CalendarPicker
          date={withdrawalDate}
          onChange={(newDate) => setWithdrawalDate(newDate)}
          minDate={
            minDate ? DateTime.fromFormat(minDate, "dd/LL/yyyy") : undefined
          }
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 1.5,
            mt: 0.5,
          }}
        >
          {!buttonLoader ? (
            <>
              <Button variant="outlined" onClick={closeModal}>
                Cancel
              </Button>
              <Button type="submit" variant="contained" onClick={submitHandler}>
                Save
              </Button>
            </>
          ) : (
            <CircularProgress size={25} />
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default DatepickerModal;

import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { HeaderLeftContent, StyledHeader } from "../Common/styles/header";
import { ChevronLeft, HighlightOff } from "@mui/icons-material";
import { DateTime } from "luxon";
import { errorToastMessage } from "../../utils/toast";
import { CalendarPicker, PickersDay } from "@mui/x-date-pickers";
import {
  ApptContainer,
  CalendarPickerWrapper,
  CalendarScreensWrapper,
  CalendarWrapper,
  RowContainer,
} from "../MyCalendar/style";
import { InputWrapper, LabelStyle } from "../Common/styles/form";
import { LoadingContainer } from "../CMS/cms.style";
import { debounce } from "lodash";
import { AxiosResponse } from "axios";
import http from "../../utils/http";
import ConfirmationModal from "./ConfirmationModal";
import { getColor, slots } from "../../utils/schedule";
import {
  GridContainer,
  dateWrapper,
} from "../UserManagement/Participants/style";
import UnassignSlotModal from "./UnassignSlotModal";

const SlotItem = ({
  data,
  slot,
  handleSlotSelection,
  makeUnavailableSlots,
  openModal,
}: any) => {
  const timing = data.find((t: any) => t?.startTime === slot);
  const status = timing ? timing?.status : "disabled";
  const allowSelection = ["available"].includes(status);

  const editedStatus = makeUnavailableSlots.includes(timing?.id)
    ? "proposed"
    : timing
    ? timing?.status
    : "disabled";

  return (
    <Tooltip title={timing?.assignedTo} placement="top">
      <Box
        key={slot}
        sx={{
          ...dateWrapper,
          borderColor: getColor(editedStatus, false, "border"),
          color: getColor(editedStatus, false, "text"),
          cursor: allowSelection ? "pointer" : "default",
        }}
        onClick={() => {
          if (allowSelection) handleSlotSelection(status, timing);
        }}
      >
        {status === "proposed" && (
          <Box
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
            }}
          >
            <IconButton size="small" onClick={() => openModal(timing?.id)}>
              <HighlightOff color="error" sx={{ fontSize: 16 }} />
            </IconButton>
          </Box>
        )}
        <Typography fontWeight={500} fontSize={18} fontStyle="normal">
          {`${slot} - ${DateTime.fromFormat(slot || "", "hh:mm a")
            .plus({ hour: 1 })
            .toFormat("hh:mm a")}`}
        </Typography>
      </Box>
    </Tooltip>
  );
};

const AssignSlots = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [searchLoader, setSearchLoader] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [date, setDate] = useState<DateTime | null>(
    searchParams.get("date") &&
      DateTime.fromFormat(searchParams.get("date") || "", "dd-LL-yyyy").isValid
      ? DateTime.fromFormat(searchParams.get("date") || "", "dd-LL-yyyy")
      : DateTime.now()
  );
  const [data, setData] = useState<any[]>([]);
  const [selectedSlots, setSelectedSlots] = useState<string[]>([]);
  const [patients, setPatients] = useState<any>([]);
  const [selectedPatient, setSelectedPatient] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [month, setMonth] = useState(
    date ? date.toISO() : DateTime.now().toISO()
  );
  const [monthData, setMonthData] = useState<any[]>([]);
  const [slotId, setSlotId] = useState("");

  useEffect(() => {
    const params = new URLSearchParams();
    if (date) {
      params.set("date", date?.toFormat("dd-LL-yyyy"));
    }

    setSearchParams(params, {
      replace: true,
    });
  }, [setSearchParams, date]);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        const coachId = localStorage.getItem("coachId");
        let url = `/consultation/availability?coachId=${coachId}&startDate=${date
          ?.startOf("day")
          .toUTC()
          .toISO()}&endDate=${date?.endOf("day").toUTC().toISO()}`;
        const res: AxiosResponse = await http.get(url);
        const resData = res.data?.data;

        const newData = resData?.map((item: any) => ({
          id: item.id,
          startTime: DateTime.fromISO(item?.startTime).toFormat("hh:mm a"),
          endTime: item?.endTime
            ? DateTime.fromISO(item?.endTime).toFormat("hh:mm a")
            : DateTime.fromISO(item?.startTime)
                .plus({ hour: 1 })
                .toFormat("hh:mm a"),
          user: item.userId,
          assignedTo: item?.guest?.code || "",
          status: item.assignedTo
            ? item.isConfirmed
              ? "unavailable"
              : "proposed"
            : "available",
        }));
        setData(newData);

        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    fetchDetails();
  }, [setLoading, setData, toggle, date]);

  // useEffect(() => {
  //   const fetchDetails = async () => {
  //     try {
  //       setLoading(true);
  //       const coachId = localStorage.getItem("coachId");
  //       let url = `/consultation/availability?coachId=${coachId}&startDate=${date
  //         ?.startOf("day")
  //         .toUTC()
  //         .toISO()}&endDate=${date?.endOf("day").toUTC().toISO()}`;
  //       const res: AxiosResponse = await http.get(url);
  //       const resData = res.data?.data;

  //       const newData = resData
  //         ?.filter((item: any) => !item?.assignedTo)
  //         ?.filter((item: any) => {
  //           return DateTime.fromISO(item?.startTime) > DateTime.local();
  //         })
  //         .map((item: any) => ({
  //           id: item?.id,
  //           // time: DateTime.fromISO(item.dateTime).toFormat("hh:mm a"),
  //           startTime: DateTime.fromISO(item?.startTime).toFormat("hh:mm a"),
  //           endTime: item?.endTime
  //             ? DateTime.fromISO(item?.endTime).toFormat("hh:mm a")
  //             : DateTime.fromISO(item?.startTime)
  //                 .plus({ hour: 1 })
  //                 .toFormat("hh:mm a"),
  //           status: "available",
  //         }))
  //         .sort((a: any, b: any) => {
  //           return (
  //             (DateTime.fromFormat(a?.startTime || "", "hh:mm a") as any) -
  //             (DateTime.fromFormat(b?.startTime || "", "hh:mm a") as any)
  //           );
  //         });

  //       setData(newData || []);

  //       setLoading(false);
  //     } catch (err) {
  //       setLoading(false);
  //       errorToastMessage(err as Error);
  //     }
  //   };
  //   fetchDetails();
  // }, [date, toggle]);

  useEffect(() => {
    const getMonthData = async () => {
      try {
        const coachId = localStorage.getItem("coachId");
        let startDate = DateTime.fromISO(month)
          .startOf("month")
          .toUTC()
          .toISO();
        let endDate = DateTime.fromISO(month).endOf("month").toUTC().toISO();

        // if (DateTime.now() > DateTime.fromISO(month)) {
        //   startDate = DateTime.now().startOf("day").toUTC().toISO();
        // }
        if (DateTime.now() > DateTime.fromISO(startDate)) {
          startDate = DateTime.now().toUTC().toISO();
        }
        const res = await http.get(
          `/consultation/unassigned/count?startDate=${startDate}&endDate=${endDate}&coachId=${coachId}`
        );
        setMonthData(res?.data?.data);
      } catch (error) {
        errorToastMessage(error as Error);
      }
    };

    getMonthData();
  }, [month, date, toggle]);

  // const handleSelectSlot = (id: string) => {
  //   if (selectedSlots.includes(id)) {
  //     setSelectedSlots((prev) => prev?.filter((slot) => slot !== id));
  //   } else {
  //     setSelectedSlots((prev) => [...prev, id]);
  //   }
  // };

  const handleBack = () => {
    navigate(-1);
  };

  const handleSearch = useMemo(
    () =>
      debounce(async (value: string) => {
        try {
          setSearchLoader(true);
          const coachId = localStorage.getItem("coachId");
          let url = `/coach/participant?doctorId=${coachId}&search=${value}`;

          const res: AxiosResponse = await http.get(url);
          const patientsData = res.data?.data?.map((item: any) => {
            return {
              id: item?.participant?.id,
              name: item?.participant?.code,
            };
          });
          setPatients(patientsData || null);
          setSearchLoader(false);
        } catch (err) {
          errorToastMessage(err as Error);
          setSearchLoader(false);
        }
      }, 500),
    []
  );

  useEffect(() => {
    handleSearch("");
  }, [handleSearch]);

  const handleAssign = async () => {
    try {
      setSubmitLoader(true);
      if (!selectedPatient) {
        throw new Error("Please select a patient to assign");
      }
      if (!selectedSlots.length) {
        throw new Error("Please select a slot to assign");
      }
      setShowModal(true);
      setSubmitLoader(false);
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const refreshPage = () => {
    setToggle((prev) => !prev);
  };

  const handleSlotSelection = (status: string, timing: any) => {
    if (status === "available") {
      setSelectedSlots((prev: any) => {
        if (prev.includes(timing.id)) {
          return prev.filter((item: string) => item !== timing.id);
        } else return [...prev, timing?.id];
      });
    }
  };

  const openUnassignModal = (slotId: string) => {
    setSlotId(slotId);
  };

  const closeUnassignModal = () => {
    setSlotId("");
  };

  return (
    <>
      <StyledHeader>
        <Box sx={{ ...HeaderLeftContent, gap: 0 }}>
          <IconButton onClick={handleBack}>
            <ChevronLeft fontSize="large" htmlColor="#111928" />
          </IconButton>
          <Typography fontSize={30} fontWeight="bold">
            Assign slots
          </Typography>
        </Box>
      </StyledHeader>
      <Box sx={CalendarScreensWrapper}>
        <Box sx={CalendarWrapper}>
          <Box sx={RowContainer}>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" fontWeight="medium" sx={{ mb: "50px" }}>
                Date
              </Typography>
              <Box sx={CalendarPickerWrapper}>
                <CalendarPicker
                  date={date}
                  onMonthChange={(newMonth) => {
                    setMonth(newMonth.toISO());
                  }}
                  onChange={(newDate: any) => {
                    setDate(newDate);
                  }}
                  disablePast
                  views={["day"]}
                  className="calendar-view"
                  renderDay={(day, _value, DayComponentProps) => {
                    const dayData = monthData?.find((item) =>
                      DateTime.fromISO(item?.date).hasSame(day, "day")
                    );

                    let backgroundColor = "#d71440";
                    let borderColor = "#d71440 !important";

                    if (dayData?.count >= 2) {
                      backgroundColor = " #07B152";
                      borderColor = " #07B152 !important";
                    } else if (dayData?.count === 1) {
                      backgroundColor = "#FFC20A";
                      borderColor = "#FFC20A !important";
                    }
                    return DayComponentProps?.disabled ? (
                      <PickersDay {...DayComponentProps} />
                    ) : (
                      <PickersDay
                        {...DayComponentProps}
                        sx={{
                          backgroundColor: backgroundColor,
                          color: "#ffffff !important",
                          borderColor: borderColor,
                          "&:hover": {
                            backgroundColor: backgroundColor,
                          },
                        }}
                      />
                    );
                  }}
                />
              </Box>
            </Box>
            <Divider
              flexItem
              orientation="vertical"
              sx={{
                mt: "80px",
                // pl: "10%",
                borderColor: "#D7D7D7",
              }}
            />
            <Box sx={{ ...ApptContainer, border: "none" }}>
              <Typography variant="h6" fontWeight="medium" sx={{ mb: "20px" }}>
                Patient
              </Typography>
              <FormControl sx={{ ...InputWrapper, mb: 5 }}>
                <FormLabel sx={LabelStyle} htmlFor="patient">
                  Patient
                </FormLabel>
                <Autocomplete
                  key={selectedPatient ? selectedPatient?.id : ""}
                  filterOptions={(x) => x}
                  onInputChange={(_1: any, value: any, reason: string) => {
                    if (reason === "input") handleSearch(value);
                  }}
                  onChange={(_1: any, value: any) => {
                    setSelectedPatient(value);
                  }}
                  options={patients}
                  value={selectedPatient || null}
                  getOptionLabel={(option) => option?.name}
                  isOptionEqualToValue={(option, value) => {
                    return option?.id === value?.id;
                  }}
                  loading={searchLoader}
                  loadingText={<CircularProgress size={20} />}
                  noOptionsText="No Results"
                  clearOnBlur={false}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Search..." />
                  )}
                />
              </FormControl>
              <Typography variant="h6" fontWeight="medium" sx={{ mb: 3 }}>
                Time
              </Typography>
              {!loading ? (
                // data?.length ? (
                //   <>
                //     <Box sx={GridContainer}>
                //       {data?.map((timing) => {
                //         const isSelected = selectedSlots.includes(timing.id);

                //         return (
                //           <Box
                //             key={timing.id}
                //             sx={{
                //               ...dateWrapper,
                //               borderColor: getColor(
                //                 timing?.status,
                //                 isSelected,
                //                 "border"
                //               ),
                //               color: getColor(
                //                 timing?.status,
                //                 isSelected,
                //                 "text"
                //               ),
                //             }}
                //             onClick={() => {
                //               handleSelectSlot(timing?.id);
                //             }}
                //           >
                //             <Typography
                //               fontWeight={500}
                //               fontSize={18}
                //               fontStyle="normal"
                //             >
                //               {timing?.startTime} - {timing?.endTime}
                //             </Typography>
                //           </Box>
                //         );
                //       })}
                //     </Box>
                //     <Box
                //       sx={{
                //         display: "flex",
                //         justifyContent: "flex-end",
                //         mt: "40px",
                //       }}
                //     >
                //       {!submitLoader ? (
                //         <Button variant="contained" onClick={handleAssign}>
                //           Assign slots
                //         </Button>
                //       ) : (
                //         <CircularProgress size={18} />
                //       )}
                //     </Box>
                //   </>
                // ) : (
                //   <Typography variant="body1" color="gray">
                //     No slots available
                //   </Typography>
                // )
                <>
                  <Box sx={GridContainer}>
                    {slots.map((slot) => (
                      <SlotItem
                        slot={slot}
                        data={data}
                        key={slot}
                        handleSlotSelection={handleSlotSelection}
                        makeUnavailableSlots={selectedSlots}
                        openModal={openUnassignModal}
                      />
                    ))}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      mt: "40px",
                    }}
                  >
                    {!submitLoader ? (
                      <Button
                        variant="contained"
                        onClick={handleAssign}
                        disabled={!selectedSlots.length}
                      >
                        Assign slots
                      </Button>
                    ) : (
                      <CircularProgress size={18} />
                    )}
                  </Box>
                </>
              ) : (
                <Box sx={{ ...LoadingContainer, flex: 1 }}>
                  <CircularProgress size={25} />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      {showModal && (
        <ConfirmationModal
          showModal={showModal}
          closeModal={closeModal}
          patient={selectedPatient}
          slots={selectedSlots}
          refreshPage={refreshPage}
          setPatient={setSelectedPatient}
          setSlots={setSelectedSlots}
        />
      )}
      {slotId && (
        <UnassignSlotModal
          showModal={slotId}
          closeModal={closeUnassignModal}
          refreshPage={refreshPage}
        />
      )}
    </>
  );
};

export default AssignSlots;

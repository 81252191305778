export const getColor = (status: string, isSelected: boolean, type: string) => {
  if (isSelected) {
    return "#FFC20A";
  } else {
    if (status === "available") {
      return "#30D073";
    } else if (status === "proposed") {
      return "#FFC20A";
    } else if (status === "unavailable") {
      return "#FF7373";
    } else return "#BDBDBD";
  }
};

export const slots = [
  "08:00 AM",
  "09:00 AM",
  "10:00 AM",
  "11:00 AM",
  "12:00 PM",
  "01:00 PM",
  "02:00 PM",
  "03:00 PM",
  "04:00 PM",
  "05:00 PM",
  "06:00 PM",
  "07:00 PM",
  "08:00 PM",
  "09:00 PM",
];

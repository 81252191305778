import { useEffect, useState } from "react";
import http from "../../../utils/http";
import { useParams, Link as RouterLink } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Breadcrumbs,
  Link,
  LinkProps,
  Button,
  Divider,
} from "@mui/material";
import { errorToastMessage } from "../../../utils/toast";
import { AxiosResponse } from "axios";
import { JournalCard, headerContainer } from "./style";
import GoalEditModal from "./GoalEditModal";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import CvdEditModal from "./CvdEditModal";
import { DateTime } from "luxon";
import { riskCategoriesMap } from "../../../utils/learningPath";
import AssistantAssignSlots from "../../Schedule/AssistantAssignSlots";
import ProfileCard from "./ProfileCard";
import AssignDoctorModal from "./AssignDoctorModal";
import DatepickerModal from "./DatepickerModal";

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

function LinkRouter(props: LinkRouterProps) {
  return <Link {...props} component={RouterLink as any} />;
}

const ParticipantDetails = () => {
  const {
    hasHealthCoachAccess,
    hasMasterAdminAccess,
    hasClinicalAssistantAccess,
  } = useAppSelector((state: any) => state.user);
  const ptpType = useAppSelector((state) => state.participants?.type);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [participantData, setParticipantData] = useState<any>({});
  const [steps, setSteps] = useState("3000");
  const [sleep, setSleep] = useState("540");
  const [type, setType] = useState<string>("");
  const [showModal, setShowModal] = useState(false);
  const [showCvdModal, setCvdModal] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [toggleLoader, setToggleLoader] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const fetchParticipantDetails = async () => {
      try {
        setLoading(true);
        const res: AxiosResponse = await http.get(
          `/participants/${id}/profile`
        );
        const data = res.data?.data;
        // let str = "-";
        // if (data?.riskCategory) {
        //   str = data?.riskCategory?.split("_")?.[1]?.toLowerCase();
        //   if (str) {
        //     str === "active" ? (str = str + " ulcer") : (str = str + " risk");
        //   }
        // }
        const details = {
          userId: data?.userId,
          studyNumber: data?.user?.code || "Study Number",
          lang: data?.user?.lang || "",
          nickname: data?.nickname || "",
          riskCategory: riskCategoriesMap[data?.riskCategory] || "",
          gender: data?.gender || "",
          ethnicity: data?.ethnicity || "",
          isSelf:
            data?.isSelf === null ? "" : data?.isSelf ? "Patient" : "Caregiver",
          cvdRisk: data?.cvd?.toString() || "",
          ldl: data?.ldl?.toString() || "",
          nextMeeting: data?.user?.consultation
            ? DateTime.fromISO(data?.user?.consultation?.scheduledAt).toFormat(
                "dd LLLL yyyy hh:mm a"
              )
            : "",
          consultationId: data?.user?.consultation
            ? data?.user?.consultation?.id
            : "",
          onboardingDate: data?.user?.participantOnboarding?.updatedAt
            ? DateTime?.fromISO(
                data?.user?.participantOnboarding?.updatedAt
              )?.toFormat("dd/LL/yyyy")
            : "",
          withdrawalDate: data?.withdrawalDate
            ? DateTime?.fromISO(data?.withdrawalDate)?.toFormat("dd/LL/yyyy")
            : "",
          coachName: data?.user?.doctor
            ? data?.user?.doctor?.doctor?.firstName +
              " " +
              data?.user?.doctor?.doctor?.lastName
            : "",
          coachId: data?.user?.doctor ? data?.user?.doctor?.doctorId : "",
        };
        if (!hasClinicalAssistantAccess) {
          const response: AxiosResponse = await http.get(
            `/activity/goal-settings/${id}?type=step`
          );
          setSteps(response?.data?.data?.goal || "3000");

          const sleepResponse: AxiosResponse = await http.get(
            `/activity/goal-settings/${id}?type=sleep`
          );
          setSleep(sleepResponse?.data?.data?.goal || "540");
        }
        setParticipantData(details);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };

    fetchParticipantDetails();
  }, [id, toggleLoader, dispatch, hasClinicalAssistantAccess]);

  const refreshPage = () => {
    setToggleLoader((prev) => !prev);
  };

  const openModal = (visible: boolean, typeValue: string) => {
    setShowModal(visible);
    setType(typeValue);
  };

  const openCvdModal = (visible: boolean, typeValue: string) => {
    setCvdModal(visible);
    setType(typeValue);
  };

  const closeModal = () => {
    setShowModal(false);
    setCvdModal(false);
    setType("");
  };

  const startConsultation = () => {
    window.open(
      `/consultation/${id}?consultationId=${participantData?.consultationId}&user=${participantData?.studyNumber}`,
      "_blank"
    );
  };

  const closeAssignModal = () => {
    setShowAssignModal(false);
  };

  return (
    <Box
      sx={{
        height: "100%",
        overflow: "auto",
        p: 2,
        // bgcolor : "#f9fafb"
      }}
    >
      {hasHealthCoachAccess ? (
        loading ? (
          <Box
            sx={{
              ...JournalCard,
              minHeight: "250px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <ProfileCard
            participantData={participantData}
            steps={steps}
            sleep={sleep}
            openModal={openModal}
            openCvdModal={openCvdModal}
            hasHealthCoachAccess={hasHealthCoachAccess}
            hasMasterAdminAccess={hasMasterAdminAccess}
            hasClinicalAssistantAccess={hasClinicalAssistantAccess}
            startConsultation={startConsultation}
          />
        )
      ) : (
        <Box>
          <Box sx={{ ...JournalCard, minHeight: "100%", height: "auto" }}>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "250px",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Box sx={{ ...headerContainer, mb: 5 }}>
                  <Breadcrumbs>
                    <LinkRouter
                      underline="none"
                      color="#111928"
                      variant="h6"
                      fontWeight="medium"
                      to={"/app/participants"}
                    >
                      My patients
                    </LinkRouter>
                    <Link
                      underline="none"
                      variant="body1"
                      fontWeight="medium"
                      color="secondary"
                    >
                      {participantData?.studyNumber || ""}
                    </Link>
                  </Breadcrumbs>
                  {hasClinicalAssistantAccess && ptpType === "active" && (
                    <Button
                      variant="contained"
                      // disabled={participantData?.coachId ? true : false}
                      onClick={() => setShowAssignModal(true)}
                    >
                      Assign health coach
                    </Button>
                  )}
                </Box>
                <ProfileCard
                  participantData={participantData}
                  steps={steps}
                  sleep={sleep}
                  openModal={openModal}
                  hasHealthCoachAccess={hasHealthCoachAccess}
                  hasMasterAdminAccess={hasMasterAdminAccess}
                  hasClinicalAssistantAccess={hasClinicalAssistantAccess}
                />
                {hasClinicalAssistantAccess &&
                  ptpType === "active" &&
                  participantData?.coachId && (
                    <>
                      <Divider sx={{ my: "20px" }} />
                      <AssistantAssignSlots
                        coachId={participantData?.coachId}
                        patientId={id}
                      />
                    </>
                  )}
              </>
            )}
          </Box>
        </Box>
      )}
      {showModal && ["Steps", "Sleep"].includes(type) && (
        <GoalEditModal
          data={type === "Steps" ? steps : sleep}
          type={type}
          showModal={showModal}
          closeModal={closeModal}
          refreshPage={refreshPage}
          id={id}
        />
      )}

      {showModal && type === "Withdrawal" && (
        <DatepickerModal
          showModal={showModal}
          closeModal={closeModal}
          refreshPage={refreshPage}
          date={participantData?.withdrawalDate}
          minDate={participantData?.onboardingDate}
          id={id}
        />
      )}

      {showCvdModal && hasHealthCoachAccess && (
        <CvdEditModal
          data={
            type === "cvd" ? participantData?.cvdRisk : participantData?.ldl
          }
          type={type}
          showModal={showCvdModal}
          closeModal={closeModal}
          refreshPage={refreshPage}
          id={id}
        />
      )}

      {showAssignModal && hasClinicalAssistantAccess && (
        <AssignDoctorModal
          showModal={showAssignModal}
          closeModal={closeAssignModal}
          selectedRows={[participantData?.userId]}
          refreshPage={refreshPage}
        />
      )}
    </Box>
  );
};

export default ParticipantDetails;

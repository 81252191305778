import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { useAppSelector } from "../../Redux/hooks";

const CMSUrlSetter = () => {
  let [, setSearchParams] = useSearchParams();
  const {
    searchText,
    type,
    educationFilter,
    languageFilter,
    educationPage,
    sortColumn,
    sortOrder,
  } = useAppSelector((state) => state.cms);

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("page", educationPage.toString());
    if (searchText) {
      params.set("search", searchText);
    }
    if (educationFilter.length > 0) {
      params.set("filter", educationFilter.join(","));
    }
    if (type) {
      params.set("type", type);
    }
    if (sortOrder) {
      params.set("order", sortOrder);
    }
    if (sortColumn) {
      params.set("sort", sortColumn);
    }
    if (languageFilter.length > 0) {
      params.set("lang", languageFilter.join(","));
    }
    setSearchParams(params, {
      replace: true,
    });
  }, [
    setSearchParams,
    searchText,
    type,
    educationFilter,
    languageFilter,
    educationPage,
    sortColumn,
    sortOrder,
  ]);

  return <></>;
};

export default CMSUrlSetter;
